import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Project Aim </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    <p> The main topic addressed by our project is to promote education in and through sport with special focus on skills development of coaches that work in the domain of adapted/unified sport, in general and adapted/unified dance, in particular.
<br/><br/> Our topic is also directly related to the topic - Social inclusion and equal opportunities in sport as a part of our local activities will be centered on this topic too.

<br/><br/> Our project's aim is to promote and support mutual learning instruments, cross-disciplinary research aiming at an analytical evidence-based framework, and the exchange of knowledge and best practices in the domain of adaptive/unified dance/sport, through seminars and meetings of expert groups on issues relating to dance and social inclusion and ensure that the results of such activities are effectively disseminated and connected to practice in each of the participant countries.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic